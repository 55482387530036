<template>
  <div class="searchInput">
    <!-- 快速搜索 -->
    <div class="topSearch">
      <el-select
        v-show="mode != 'reports'"
        v-model="value"
        placeholder="请选择"
        class="leftSelect"
        @change="changeSelectBtn"
        filterable
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        ></el-option>
      </el-select>

      <!-- 非-质检单-布局 -->
      <div class="centerBox" v-if="mode != 'reports'">
        <el-select
          v-model="selectVlaue"
          :placeholder="'请选择'+options.find(e=>e.value==value).name"
          class="centerSelect"
          v-if="type == 'select'"
          filterable
          clearable
          :remote="remote == true ? true : false"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in selectOption"
            :key="item.value"
            :label="item.label"
            :value="keyType == 'label' ? item.label : item.value"
          ></el-option>
        </el-select>

        <el-input
          v-model="input"
          prefix-icon="el-icon-search"
          :placeholder="'请输入'+(options.length>0?options.find(e=>e.value==value).name:'')"
          v-if="type == 'input'"
          class="centerInput"
          clearable
        ></el-input>
      </div>
      <!-- 质检单-布局 -->
      <div class="center2Box" v-else>
        <el-select
          v-model="selectVlaue"
          placeholder="请选择产品名称"
          class="centerSelect"
          filterable
          clearable
          @change="selectChangeFun"
        >
          <el-option
            v-for="item in selectOption"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
        <el-select
          v-model="select2Vlaue"
          placeholder="请选择批号"
          class="centerSelect"
          filterable
          clearable
        >
          <el-option
            v-for="item in select2Option"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </div>

      <!-- <div class="rightBtn cursorpointer" @click="searchBtn">搜索</div>
      <div
        class="rightMoreBtn cursorpointer"
       
        @click="seniorSearch"
      >
        高级搜索
        <i
          :class="
            showSeniorSearchFlag == true
              ? 'activeIcon el-icon-caret-bottom'
              : 'activeDow el-icon-caret-bottom'
          "
        ></i>
      </div> -->
      <el-button  class="rightBtn cursorpointer" :loading="$store.state.searchBtnLoading" :disabled="$store.state.searchBtnLoading"  @click="searchBtn">搜索</el-button>
      <el-button   class="rightMoreBtn cursorpointer" 
          v-if="hasSeniorSearch == true"
          @click="seniorSearch">
          高级搜索
          <i
          :class="
            showSeniorSearchFlag == true
              ? 'activeIcon el-icon-caret-bottom'
              : 'activeDow el-icon-caret-bottom'
          "
        ></i>
      </el-button>

    </div>

    <!-- 高级搜索 -->
    <div class="bottomSearch" v-show="showSeniorSearchFlag == true">
      <div class="content">
        <div v-for="(item, index) of seniorSearchList" :key="index" class="box" >
          <el-date-picker
            class="timeDataCla"
            size="medium"
            v-if="item.type == 'timeData'"
            v-model="item.value"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
            :picker-options="['orders','dispatchs'].includes(mode)?'':pickerOptions"
          ></el-date-picker>

          <el-input
            v-model="item.value"
            :placeholder="'请输入'+item.name"
            v-if="item.type == 'input'"
            class="centerInput"
            clearable
            size="medium"
          ></el-input>

          <el-select
            v-model="item.value"
            :placeholder="'请选择'+item.name"
            class="centerSelect"
            v-if="item.type == 'select' && item.remote == undefined "
            @change="changeSelect($event, item)"
            filterable
            clearable
            size="medium"
          >
            <el-option
              v-for="item in item.selectOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-select
            v-model="item.value"
            :placeholder="'请选择'+item.name"
            class="centerSelect"
            v-if="item.type == 'select' && item.remote == true"
            filterable
            clearable
            remote
            size="medium"
            :remote-method="
              (query) => {
                remoteMethodSenior(query, item, index)
              }
            "
          >
            <el-option
              v-for="item in item.selectOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="btnBox">
        <el-button class="btn" :loading="$store.state.searchBtnLoading" :disabled="$store.state.searchBtnLoading" @click="searchBtn">搜索</el-button>
        <el-button class="btn" @click="seniorSearch">切换快速搜索</el-button>

        <!-- <p @click="searchBtn">搜索</p>
        <p @click="seniorSearch">切换快速搜索</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import { searchTable } from '@/assets/js/clickSearch.js'
import { eventBus } from '@/main'
import {
  getPlatform,
  getSampletype,
  dimension_warning,
  reagent_list,
  hospitals_list,
  hospitals_name_list,
  organization_list,
  search_customer,
} from '@/api/search.js'
export default {
  props: ['mode'],
  data() {
    return {
      value: '',
      options: [],
      keyType: 'value',
      platform_id: '',
      type: 'input',
      remote: false, //是否开启远程搜索
      typeValue: '',
      oldtypeValue: '',
      pickerOptions: {
        disabledDate(time) {
            return time.getTime() > Date.now();
        }
      },
      reportData: {},
      // 中间输入框
      input: '',
      //中间筛选框
      selectOption: [],
      selectVlaue: '',
      select2Vlaue: '',
      select2Option: [],
      org_type: JSON.parse(localStorage.getItem('userInfo')).org_type,
      hasSeniorSearch: false, //是否有高级搜索
      seniorSearchList: [], //高级搜索列表
      showSeniorSearchFlag: false, //是否展示高级搜索

      inventory_typeOption: [
        { value: '0', label: '平台' },
        { value: '1', label: '试剂' },
      ],

      diarysOption: [
        { value: '1', label: '装机' },
        { value: '2', label: '维修' },
        { value: '3', label: '保养' },
        { value: '4', label: '校准' },
        { value: '5', label: '性能验证' },
        { value: '6', label: '展会' },
        { value: '7', label: '拜访' },
        { value: '8', label: '代理商' },
        { value: '9', label: '远程' },
      ],
      articleapplisOption: [
        { value: '1', label: '备件' },
        { value: '2', label: '耗材' },
        { value: '3', label: '电子标签' },
      ],
      resultOption: [
        { value: 'False', label: '阴性' },
        { value: 'True', label: '阳性' },
      ],
      chuanciOption: [
        { value: 'False', label: '否' },
        { value: 'True', label: '是' },
      ],
      changneiOption: [
        { value: '0', label: '非厂内' },
        { value: '1', label: '厂内' },
      ],
    }
  },
  watch: {
    mode(value) {
      this.hasSeniorSearch = false
      this.seniorSearchList = []
      this.input = ''
      this.showSeniorSearchFlag = false
      this.selectOption = []
      this.selectVlaue = ''
      this.value = ''
      this.options = []
      this.options = searchTable[value].currentSelect

      if (value == 'reports') {
        this.select2Option = []
        this.select2Vlaue = ''
        this.selectOption = reagent_list()
      } else {
        // 左选框默认第一个
        this.changeSelectBtn(this.options[0].value);
        this.value = this.options[0].value;
        if (searchTable[value].seniorSelect == undefined) {
          this.hasSeniorSearch = false
        } else {
          this.hasSeniorSearch = true
          // this.seniorSearchList = searchTable[value].seniorSelect
          this.seniorSearchList = JSON.parse(JSON.stringify(searchTable[value].seniorSelect)); 
        }
      }
    },
  },
  methods: {
    // 监听左边第一个选项框选择数据
    changeSelectBtn(value) {
      if(this.typeValue === value) return
      this.oldtypeValue = this.typeValue
      this.typeValue = value
      this.selectOption = []
      this.selectVlaue = ''
      this.input = ''
      let arr = this.options.filter((e) => value == e.value)
      this.type = arr[0].type
      this.remote = arr[0].remote ? arr[0].remote : false
      this.filterTypeFun(arr[0])

    },

    //第二个选项下拉框 - 启用远程搜索
    remoteMethod(e) {
      if (this.remote == false) {
        return
      }
      if (this.value == 'hospital_id') {
        //医院
        this.selectOption = hospitals_list({ hospital_name: e })
      } else if (this.value == 'hospital_name') {
        //医院 名称
        this.selectOption = hospitals_name_list({ hospital_name: e })
      } else if (this.value == 'organization_id') {
        //收货单位 ID
        this.selectOption = organization_list({ organization_name: e })
      }else if (this.value == 'customer_id') {
        //经销商
        this.selectOption = search_customer({ customer_name: e })
      }
    },

    //第二个选项下拉框 - 非远程搜索、输入框数据
    filterTypeFun(value) {
      if (value.type == 'select') {
        if (value.value == 'inventory_type') {
          //产品类型
          this.selectOption = this.inventory_typeOption
        } else if (value.value == 'platform_id') {
          //平台 
          this.selectOption = getPlatform()
        } else if (value.value == 'platform_unique') {
          //平台传英文名
          this.keyType = 'label'
          this.selectOption =getPlatform(this.mode)
        } else if (value.value == 'province_id') {
          //省份
          this.selectOption = JSON.parse(localStorage.getItem('province'))
        } else if (value.value == 'testing_id') {
          //检测项目
          this.selectOption = JSON.parse(localStorage.getItem('testing'))
        } else if (value.value == 'diary_type') {
          // 日志类型
          this.selectOption = this.diarysOption
        } else if (value.value == 'warning_id') {
          // 告警代码
          this.selectOption = dimension_warning()
        } else if (value.value == 'qc_rule_c1'||value.value == 'qc_rule_c2') {
          // 违背规则
          this.selectOption = [
            {label: '1-2S',value: '1-2S'},
            {label: '1-2.5S',value: '1-2.5S'},
            {label: '1-3S',value: '1-3S'},
            {label: '2-2S',value: '2-2S'},
            {label: '3-1S',value: '3-1S'},
            {label: '4-1S',value: '4-1S'},
            {label: '6-X',value: '6-X'},
            {label: '8-X',value: '8-X'},
            {label: '10-X',value: '10-X'},
            {label: '12-X',value: '12-X'},
          ]
        } 
      }
    },

    // 高级选项-初始调用接口
    seniorSearch() {
      this.showSeniorSearchFlag = !this.showSeniorSearchFlag
      if (this.showSeniorSearchFlag == false) { //高级搜索切换快速搜索
        this.seniorSearchList.forEach((e) => { 
          if(this.value == e.list){  
            if(e.type === 'select'){
              this.selectVlaue= e.value
              this.selectOption =  e.selectOption
            }else if(e.type === 'input'){
              this.input  = e.value
            }
          }
          e.value = ''
        }) 
      } else {  // 快速搜索切换至高级搜索
        let seniorSearchList = this.seniorSearchList
        seniorSearchList.forEach(e => {
          if(this.value == e.list){
            if(e.type === 'select'){
              e.value = this.selectVlaue
              e.selectOption = this.selectOption 
            }else if(e.type === 'input'){
              e.value = this.input
            }
          }
        })
        switch (this.mode) {
          case 'instruments': //仪器
            seniorSearchList.forEach((e) => {
              if (e.list == 'platform_id') {
                e.selectOption = getPlatform()
              }else if (e.list == 'is_stab') {
                e.selectOption = this.chuanciOption
              }else if (e.list == 'is_factory') {
                e.selectOption = this.changneiOption
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'kits': //试剂
            seniorSearchList.forEach((e) => {
              if (e.list == 'testing_id') {
                e.selectOption = JSON.parse(localStorage.getItem('testing'))
              }else if(e.list == 'platform_unique') {
                e.selectOption = getPlatform(this.mode)
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'qcs': //质控
            seniorSearchList.forEach((e) => {
              if (e.list == 'testing_id') {
                e.selectOption = JSON.parse(localStorage.getItem('testing'))
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'calibrations': //定标
            seniorSearchList.forEach((e) => {
              if (e.list == 'testing_id') {
                e.selectOption = JSON.parse(localStorage.getItem('testing'))
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'cycle_qc_data_list': //失控
            seniorSearchList.forEach((e) => {
              if (e.list == 'testing_id') {
                e.selectOption = JSON.parse(localStorage.getItem('testing'))
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              } else if (e.list == 'qc_rule_c1'||e.list == 'qc_rule_c2') {
                // 违背规则
                e.selectOption = [
                  {label: '1-2S',value: '1-2S'},
                  {label: '1-2.5S',value: '1-2.5S'},
                  {label: '1-3S',value: '1-3S'},
                  {label: '2-2S',value: '2-2S'},
                  {label: '3-1S',value: '3-1S'},
                  {label: '4-1S',value: '4-1S'},
                  {label: '6-X',value: '6-X'},
                  {label: '8-X',value: '8-X'},
                  {label: '10-X',value: '10-X'},
                  {label: '12-X',value: '12-X'},
                ]
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'recordwarnings': //告警
            seniorSearchList.forEach((e) => {
              if (e.list == 'testing_id') {
                e.selectOption = JSON.parse(localStorage.getItem('testing'))
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              } else if (e.list == 'platform_id') {
                e.selectOption = getPlatform()
              } else if (e.list == 'warning_id') {
                e.selectOption = dimension_warning()
              }
              
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'diarys': //日志
            seniorSearchList.forEach((e) => {
              if (e.list == 'diary_type') {
                e.selectOption = this.diarysOption
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'maintaintask': //保养单
            seniorSearchList.forEach((e) => {
              if (e.list == 'platform_id') {
                e.selectOption = getPlatform()
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'repairtask': //维修单
            seniorSearchList.forEach((e) => {
              if (e.list == 'platform_id') {
                e.selectOption = getPlatform()
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'installtask': //装机单
            seniorSearchList.forEach((e) => {
              if (e.list == 'platform_id') {
                e.selectOption = getPlatform()
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'articleapplis': //物品申请
            seniorSearchList.forEach((e) => {
              if (e.list == 'article_type') {
                e.selectOption = this.articleapplisOption
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          case 'samplechecks': //样本检测
            seniorSearchList.forEach((e) => {
              if (e.list == 'is_positive') {
                e.selectOption = this.resultOption
              } else if (e.list == 'platform_id') {
                e.selectOption = getPlatform()
              }else if (e.list == 'testing_id') {
                e.selectOption = JSON.parse(localStorage.getItem('testing'))
              } else if (e.list == 'province_id') {
                e.selectOption = JSON.parse(localStorage.getItem('province'))
              } else if (e.list == 'sample_type_id') {
                e.selectOption = getSampletype()
              } else if (e.list == 'warning_id') {
                e.selectOption = dimension_warning()
              }else if(e.type == 'timeData') {
                // e.value = []
                this.set_time_form_samplechecks({})
              }
            })
            this.seniorSearchList = seniorSearchList
            break
          default:
            break
        }
      }
    },

    // 高级选项-下拉选择-值-监听
    changeSelect(label, item) {
      if (item.list == 'province_id') {
        let city = item.selectOption.filter((e) => e.value == label)[0]
          .city_list
        let seniorSearchList = this.seniorSearchList
        seniorSearchList.forEach((e) => {
          if (e.list == 'city_id') {
            e.value = ''
            e.selectOption = city
          }
        })
        this.seniorSearchList = seniorSearchList

      }
      // if (item.list == 'province_id') {
      //   let city = item.selectOption.filter((e) => e.label == label)[0]
      //     .city_list
      //   let seniorSearchList = this.seniorSearchList
      //   seniorSearchList.forEach((e) => {
      //     if (e.list == 'city_id') {
      //       e.value = ''
      //       e.selectOption = city
      //     }
      //   })
      //   this.seniorSearchList = seniorSearchList
      // }
    },

    // 高级选项-远程搜搜下拉选择-值-监听
    remoteMethodSenior(value, item, index) {
      if (item.list == 'hospital_id') {
        //医院
        this.$set(this.seniorSearchList, index, {
          list: 'hospital_id',
          name: '医院',
          remote: true,
          selectOption: hospitals_list({ hospital_name: value }),
          type: 'select',
          value: '',
        })
      } else if(item.list == 'hospital_name') {
        //医院 名称
        this.$set(this.seniorSearchList, index, {
          list: 'hospital_name',
          name: '医院名',
          remote: true,
          selectOption: hospitals_name_list({ hospital_name: value }),
          type: 'select',
          value: '',
        })
      }else if(item.list == 'organization_id') {
        //医院 名称
        this.$set(this.seniorSearchList, index, {
          list: 'organization_id',
          name: '收货单位',
          remote: true,
          selectOption: organization_list({ organization_name: value }),
          type: 'select',
          value: '',
        })
      } else if (item.list == 'customer_id') {
        //经销商
        this.$set(this.seniorSearchList, index, {
          list: 'customer_id',
          name: '经销商',
          remote: true,
          selectOption: search_customer({ customer_name: value }),
          type: 'select',
          value: '',
        })
      }
    },

    // 质检单-下拉框-监听产品名称选择
    selectChangeFun(value) {
      let reagent = this.selectOption.filter((e) => e.label == value)[0]
      this.select2Option = reagent.children
      this.reportData = {
        reagent_class_id: reagent.reagent_class_id,
        report_type: reagent.report_type
      }
    },

    // 搜索
    searchBtn() {
      let obj = {}
      if (this.type == 'input') {
        this.input == '' ? '' : (obj[this.typeValue] = this.input)
      } else if (this.type == 'select') {
        obj[this.typeValue] = this.selectVlaue
      }
      if (this.mode == 'reports') {
        // obj.reagent_name = this.selectVlaue
        obj = {...this.reportData}
        obj.batch = this.select2Vlaue
      }
      if (this.showSeniorSearchFlag == true) {
        this.seniorSearchList.forEach((e) => {
          if (e.type == 'timeData') {
            obj[e.list[0]] = e.value[0]
            obj[e.list[1]] = e.value[1]
          } else {
            obj[e.list] = e.value
          }
        })
      }

      // 改变搜索条件时，此处就应该把搜索页数改成1
      eventBus.$emit('pageNumReset',1);

      if(obj.kit_id){
        obj.kit_id = obj.kit_id.trim();
      }

      this.set_time_form_samplechecks(obj);

      if(this.showSeniorSearchFlag==true){
        var seniorSearchList_item = this.seniorSearchList.find(e=> e.list == 'hospital_id')
        if(seniorSearchList_item&&seniorSearchList_item.value!=''){
          obj.hospital_name = seniorSearchList_item.selectOption.find(e=>e.value==seniorSearchList_item.value).label
        }
        var seniorSearchList_item = this.seniorSearchList.find(e=> e.list == 'customer_id')
        if(seniorSearchList_item&&seniorSearchList_item.value!=''){
          obj.customer_name = seniorSearchList_item.selectOption.find(e=>e.value==seniorSearchList_item.value).label
        }
      }else if(this.value=='hospital_id'){
        if(this.selectVlaue){
          obj.hospital_name = this.selectOption.find(e=>e.value==this.selectVlaue).label
        }
      }else if(this.value=='customer_id'){
        if(this.selectVlaue){
          obj.customer_name = this.selectOption.find(e=>e.value==this.selectVlaue).label
        }
      }
      this.$emit('searchBtnList', obj)
    },
    // 样本检测搜索样本量过大自动设置日期范围
    set_time_form_samplechecks(obj){
        if(this.mode != 'samplechecks'){ //样本检测才生效
          return
        }
        if(obj.start_time){ //无时间范围时生效
          return
        }
      const nowdate = new Date()
      let yyyy = nowdate.getFullYear() + ''
      let MM = nowdate.getMonth() + 1,
          dd = nowdate.getDate()
      MM = MM <= 9 ? '0' + MM : MM
      dd = dd <= 9 ? '0' + dd : dd
      if(obj.instrument_id){ //有机号时查询一年之内的数据
        obj.start_time = yyyy + '-01-01'
        obj.end_time = yyyy +'-'+ MM +'-'+ dd
        this.seniorSearchList[9].value = [obj.start_time,obj.end_time]
        return
      }
      if(obj.hospital_id){ //有医院时查询一年之内的数据
        obj.start_time = yyyy + '-01-01'
        obj.end_time = yyyy +'-'+ MM +'-'+ dd
        this.seniorSearchList[9].value = [obj.start_time,obj.end_time]
        return
      }
      
      // 其他情况都设为七天
      let nowdate2 = new Date(nowdate);  
      nowdate2.setDate(nowdate.getDate()-7); 
      let MM2 = nowdate2.getMonth() + 1,
          dd2 = nowdate2.getDate();
      MM2 = MM2 <= 9 ? '0' + MM2 : MM2
      dd2 = dd2 <= 9 ? '0' + dd2 : dd2    

      obj.start_time = nowdate2.getFullYear() +'-'+ MM2 +'-' +dd2
      obj.end_time = yyyy +'-'+ MM +'-'+ dd
      this.seniorSearchList[9].value = [obj.start_time,obj.end_time]
    },
  },
}
</script>
<style lang="scss" scoped>
.searchInput {
  position: relative;
  .topSearch {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: px(179);

    .leftSelect {
      width: px(134);
      height: px(58);
      background: #ffffff;
      box-shadow: 0 px(2) px(8) 0 rgba(0, 0, 0, 0.15); 
      border-radius: px(5);
      ::v-deep .el-input__inner {
        width: px(134);
        height: px(58);
        border: none;
      }
    }
    .centerBox {
      margin: 0 px(20) 0 px(10);
      .centerSelect {
        width: px(590);
        height: px(58);
        background: #ffffff;
        box-shadow: 0 px(2) px(8) 0 rgba(0, 0, 0, 0.15);
        border-radius: px(5);
        ::v-deep .el-input__inner {
          width: px(590);
          height: px(58);
          border: none;
        }
      }

      .centerInput {
        width: px(590);
        height: px(58);
        background: #ffffff;
        box-shadow: 0 px(2) px(8) 0 rgba(0, 0, 0, 0.15);
        border-radius: px(5);
        ::v-deep .el-input__inner {
          width: px(590);
          height: px(58);
          border: none;
        }
      }
    }
    .center2Box {
      height: px(58);
      display: flex;
      .centerSelect {
        width: px(350);
        height: px(58);
        background: #ffffff;
        box-shadow: 0 px(2) px(8) 0 rgba(0, 0, 0, 0.15);
        border-radius: px(5);
        margin-right: px(10);
        ::v-deep .el-input__inner {
          width: px(350);
          height: px(58);
          border: none;
        }
      }
    }
    .rightBtn {
      width: px(108);
      height: px(58);
      background: #00aeed;
      box-shadow: 0 px(2) px(8) 0 rgba(0, 0, 0, 0.15);
      border-radius: px(5);
      font-size: px(18);
      color: #ffffff;
      text-align: center;
      margin-right: px(20);
    }
    .rightBtn:hover {
      opacity: 0.8;
    }
    .rightMoreBtn {
      width: px(134);
      height: px(58);
      background: #ffffff;
      box-shadow: 0 px(2) px(8) 0 rgba(0, 0, 0, 0.15);
      border-radius: px(5);
      font-size: px(16);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .activeIcon {
        transform: rotate(-180deg);
        transition: all 0.3s;
      }
      .activeDow {
        transform: rotate(0deg);
        transition: all 0.3s;
      }
    }
  }

  .bottomSearch {
    background: #fff;
    box-shadow: 0 px(2) px(10) 0 rgba(51, 64, 79, 0.1);
    border-radius: px(5);
    min-height: px(120);
    padding: px(20) px(20);
    width: 97.5%;
    position: absolute;
    top: px(10);
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: px(20);
    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //min-width: px(300);
        min-width: px(220);
        margin-bottom: px(10);
        .title {
          text-align: right;
          white-space: nowrap;
          margin: 0 px(10) 0 px(10);
        }
        .el-input,
        .el-input__inner,
        .el-select {
          width: px(200);
        }
        .el-date-editor--daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
          width: px(240);
        }
      }
    }
    .btnBox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: px(-10);
      .btn{
        font-size: px(18);
        background: #00aeed;
        color: #fff;
        padding: px(12) px(20);
      }
      .btn:hover {
        opacity: 0.8;
      }
      p {
        height: px(45);
        padding: 0 px(20);
        background: #00aeed;
        border-radius: px(5);
        font-size: px(18);
        line-height: px(45);
        color: #fff;
        cursor: pointer;
      }
      p:nth-child(1){
        margin-right: px(15);
      }
    }
  }
}
.timeDataCla {
  width: px(400) !important;
}
</style>